.slide-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
}
.slide-item-container .slide-item-image {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.slide-item-container .slide-item-image img {
  aspect-ratio: 2/2;
  width: 100%;
  object-fit: contain;
}

.slide-item-container .slide-item-content {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.slide-item-container .slide-item-content p {
  margin-top: 1rem;
  color: var(--text-color-60);
}

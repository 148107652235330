/* MEDIA QUERIES - RESPONSIVE CONTROL */
.full-height {
  height: 100vh;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.full-height::-webkit-scrollbar {
  display: none;
}

.visibility-xs {
  display: none;
}

.visibility-sm {
  display: none;
}

.w-md-100 {
  flex: 1;
}

.w-md-50 {
  flex: 1/2;
}

.w-sm-100 {
  flex: 0.3;
}

@media screen and (max-width: 500px) {
  :root {
    --font-size: 16px;
    --font-size-xsm: 12px;
    --font-size-sm: 13px;
    --font-size-md: 18px;
    --font-size-md-lg: 18px;
    --font-size-md-xlg: 20px;
    --font-size-lg: 30px;
    --font-size-xlg: 60px;
  }

  .hidden-xs {
    display: none !important;
  }
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .visibility-xs {
    display: flex;
  }

  .visibility-sm {
    display: flex;
  }

  .w-md-100 {
    flex: 0;
  }

  .w-sm-100 {
    flex: 1;
  }

  .table-header-container {
    display: block;
  }

  .md-height {
    height: auto;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }
  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .visibility-xs {
    display: flex;
  }

  .visibility-sm {
    display: flex;
  }

  .w-sm-100 {
    flex: 1;
  }

  .md-height {
    height: auto;
    overflow-y: auto;
  }
}

@media screen and (max-width: 980px) {
  .hidden-sm {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1;
  }

  .mb-sm-1 {
    margin-bottom: 1rem;
  }

  .w-sm-100 {
    width: 100%;
  }

  .padding-top-md-22 {
    padding-top: 1rem;
  }

  label {
    font-size: var(--font-size-sm);
  }

  .visibility-xs {
    display: flex;
  }

  .visibility-sm {
    display: flex;
  }

  .w-sm-100 {
    flex: 1;
  }

  .md-height {
    height: auto;
    overflow-y: auto;
  }
}

.separator-container {
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.main-landing-container {
  height: 100vh;
  padding-bottom: 60px;
  overflow-y: auto;
}
.landing-section .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-section-content {
  position: relative;
  padding-top: 2rem;
}

.landing-section-content .intro-img img {
  aspect-ratio: 2.5/2;
  width: 100%;
  object-fit: contain;
}

.landing-section-content .row {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
}

.auth-form-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

/* home-dashboard-layout */
.home-dashboard-layout-container {
  display: flex;
}

.dashboard-layout {
  flex: 1;
  /* background-color: var(--light-color);
  overflow-x: hidden;
  width: 100%; */
  /* height: 100%; */
  /* overflow-y: auto; */
  /* padding-bottom: 2rem; */
  padding: 1rem;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 2.5rem;
}

.center-layout {
  display: flex;
  justify-content: center;
  height: 90vh;
  overflow-y: auto;
  margin-bottom: 100px;
}

.user-guide-dashboard-layout {
  padding: 2rem 0;
  height: 100vh;
}

@media screen and (max-width: 500px) {
  .auth-form-layout {
    align-items: start;
  }
}

@media screen and (max-width: 768px) {
  .auth-form-layout {
    align-items: start;
  }
}

@media screen and (max-width: 980px) {
  .auth-form-layout {
    align-items: start;
  }
}

.pagination {
  /* margin-top: 1rem; */
  padding-right: 1rem;
}

.pagination li a {
  color: var(--text-color);
  font-weight: 600;
}

.pagination li a:hover {
  color: var(--text-color);
  background-color: var(--text-color-10);
}

.page-item.active .page-link {
  background-color: var(--text-color);
}

.pagination .page-link {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.page-link i {
  font-size: 20px;
}

.pagination .count-number {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-md);
}

@media screen and (max-width: 500px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 980px) {
  .pagination .page-link {
    width: 30px;
    height: 30px;
  }
}

.list-item {
  display: flex;
  align-items: center;
  min-height: 40px;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  padding: 0.5rem;
}

.list-item:hover {
  opacity: 0.5;
}

.list-item .list-img-container {
  width: 35px;
  height: 35px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
  border-radius: var(--border-radius-half);
}

.list-item .list-item-name {
  text-transform: capitalize;
}

.factorAuthentication-container {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.factorAuthentication-container .vector-container {
  width: 180px;
  height: 180px;
}

.factorAuthentication-container .vector-container img {
  width: 100%;
  aspect-ratio: 3/2;
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-container .image-container {
  width: 40px;
}

.logo-container img {
  aspect-ratio: 2/2;
  width: 80%;
}

.logo-container .logo-text {
  margin-left: var(--margin-sm);
  font-weight: var(--font-weight-md);
  flex: 1;
}

.logo-container .logo-text span {
  font-weight: var(--font-weight-sm);
}

.logo-container.light .logo-text {
  color: var(--light-color);
}

.logo-container .mobile-container {
  display: none;
}

.logo-container .mobile-container.show {
  display: flex;
  margin: 0 1rem 0 0.5rem;
}

nav .logo-container .mobile-container span {
  color: var(--light-color);
}

@media (max-width: 768px) {
  nav .logo-container .mobile-container {
    display: flex;
    margin-right: var(--margin);
    cursor: pointer;
  }

  nav .logo-container .image-container {
    display: none;
  }
}

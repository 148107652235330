.footer-container {
  border-top: 1px solid var(--primary-color-5);
}

.footer-container .container {
  display: flex;
  align-items: center;
  min-height: 45px;
  justify-content: center;
}

.footer-container .container span {
  font-size: var(--font-size-sm);
}

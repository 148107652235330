.text-bold {
  font-weight: var(--font-weight-md);
}

.text-capitalized {
  text-transform: capitalize;
}

.text-default {
  color: var(--text-color);
}

.text-hover-decoration:hover {
  text-decoration: underline;
}

span {
  font-size: var(--font-size);
}

p {
  font-size: var(--font-size);
  margin-bottom: 0;
  color: var(--text-color-60);
}

span {
  font-size: var(--font-size);
}

h1 {
  font-size: var(--font-size-lg);
  margin-bottom: 0;
}

h2 {
  font-size: var(--font-size-md-lg);
}

h3 {
  font-size: var(--font-size-md);
  margin-bottom: 0;
  font-weight: var(--font-weight-md);
}

.success-text {
  color: var(--success-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.error-text {
  color: var(--error-color);
}

.text-error {
  color: var(--error-color);
}

.text-disabled {
  color: var(--text-color-60);
}

.text-disabled-3 {
  color: var(--text-color-60);
  opacity: 0.5;
}

code {
  background-color: var(--text-color-10);
  color: var(--primary-color);
  padding: 0.3rem 0.5rem;
  border-radius: 2px;
}

.long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40%;
}

.button-long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

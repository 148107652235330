.btn {
  background-color: var(--primary-color);
  outline: none;
  color: var(--light-color);
  padding: 0.3rem 1rem;
  padding: 0.3rem 0.8rem;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  opacity: 1;
  transition: opacity 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  transition: background-color 0.4s;
  white-space: nowrap;
}

/* button size styles */
.btn {
  min-height: 40px;
}

.btn-sm {
  min-height: 30px;
  min-width: 30px;
  font-size: 12px;
}

.btn-xsm {
  min-height: 20px;
  padding: 5px 8px;
  font-size: 12px;
}

/* colors and other common styles */

.btn-primary {
  background-color: var(--primary-color);
  outline: none;
}

.btn-transparent {
  border: 0;
  background-color: transparent !important;
}

.btn-default {
  background-color: var(--text-color-10);
  border: 2px solid var(--text-color-10);
  color: var(--text-color-60);
  opacity: 1;
}

.btn[disabled],
.btn-disabled {
  opacity: 0.2;
}

/* end */

/* icons styles */

.btn-primary .icon {
  color: var(--light-color);
}

.btn-default .icon {
  color: var(--text-color);
}

.btn-transparent .icon {
  color: var(--text-color);
}

.btn-secondary .icon {
  color: var(--light-color);
}

/* :end */

/* hover effects */

.btn:hover,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--dark-primary-color);
  color: var(--light-color);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: var(--dark-secondary-color-10);
  color: var(--light-color);
}

.btn-danger {
  background-color: var(--error-color);
  color: var(--light-color);
  border: 0;
}

/* end hover effect */

/* border styles */

.btn-bordered {
  border: 2px solid var(--text-color-10);
  color: var(--text-color);
}

.btn-transparent {
  color: var(--text-color);
}

.btn-transparent.primary {
  color: var(--primary-color);
}

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent.bordered:hover,
.btn-transparent.bordered-md:hover,
.btn-transparent.bordered:focus,
.btn-transparent.bordered-md:focus,
.btn-transparent.primary:hover,
.btn-transparent.primary:active,
.btn-transparent.primary:focus,
.btn-transparent.bordered:hover,
.btn-transparent.bordered:active,
.btn-transparent.bordered:focus {
  color: var(--text-color);
}

.btn-transparent.light {
  color: var(--light-color);
}

.btn.danger .icon,
.btn.danger {
  color: var(--error-color);
}

.btn-transparent.light:hover,
.btn-transparent.light:focus {
  color: var(--light-color);
  opacity: 0.8;
}

.btn-transparent.primary .icon {
  color: var(--primary-color);
  font-size: 16px;
  /* margin-right: 0.5rem; */
}

.btn-transparent.light .icon {
  color: var(--light-color);
}

.btn-danger .icon {
  color: var(--light-color);
}

.btn .left-img-container {
  width: 25px;
  height: 25px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.btn .left-img {
  aspect-ratio: 3/3;
  object-fit: contain;
  width: 80%;
  border-radius: var(--border-radius-half);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  border: 2px solid var(--text-color-10);
  background-color: var(--text-color-10);
  color: var(--text-color);
}

.menu-container-no-shadow .dropdown-menu {
  box-shadow: none !important;
}

.btn-disabled {
  opacity: 0.5;
}

.search-dropdown-text-selected {
  font-weight: 600;
}

.btn-danger {
  background-color: var(--error-color);
  color: var(--light-color);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  opacity: 0.8 !important;
  color: var(--light-color);
  background-color: var(--error-color);
  border: 0;
}

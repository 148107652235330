.intro-container {
  background-color: var(--primary-color);
}

.intro-container .landing-section-content .intro-text-container {
  color: var(--light-color);
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.intro-container .landing-section-content .intro-text-container h1 {
  text-transform: capitalize;
}

.intro-container .landing-section-content .intro-text-container p {
  color: var(--primary-color-10);
  max-width: 500px;
  font-size: 15px;
  opacity: 0.7;
}

.intro-container .search-container {
  margin-bottom: -20px;
}

.intro-container .landing-section-content .row {
  align-items: center;
}

@media screen and (max-width: 500px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 980px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

.user-container {
  display: flex;
  min-height: 60px;
  gap: 1.5rem;
}

.user-left-container {
  display: flex;
  gap: 1.5rem;
  flex: 1;
  flex-wrap: wrap;
}

.user-container .user-img-container {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-img-container img {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-name-container {
  flex: 1;
}

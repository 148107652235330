.dropdown-menu {
  border: 1px solid var(--primary-color-5);
  padding: 0;
  box-shadow: var(--dropdown-shadow);
}

.dropdown-content {
  min-width: 300px;
  max-height: 50vh;
  overflow-y: auto;
}

.dropdown-header-container {
  border-bottom: 1px solid var(--primary-color-5);
  padding: 0.3rem 1rem;
  min-height: 40px;
}

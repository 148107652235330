.request-service-document .document-item {
  display: flex;
  min-height: 150px;
  border-bottom: 1px solid var(--primary-color-5);
  margin-bottom: 0.5rem;
  /* flex-wrap: wrap; */
  padding: 1rem 0;
  border-bottom: 2px solid var(--text-color-5);
}

.document-item.document-error {
  border: 2px solid var(--error-color);
}

.request-service-document .document-item .document-name {
  flex: 1;
  margin-right: 1rem;
}

.request-service-document .document-item .file-container .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.request-service-document .document-item .count-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  margin-right: 1rem;
}

.request-service-document .document-item .count-container span {
  color: var(--light-color);
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-sm);
}

.uploaded-file-container .name {
  color: var(--primary-color);
  font-weight: var(--font-weight-md);
  margin-right: 1rem;
}

.uploaded-file-container .file-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  background-color: var(--primary-color-5);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.uploaded-file-container .extension {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.uploaded-file-container .extension img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 100%;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid var(--primary-color-10);
}
.editor-class {
  background-color: var(--text-color-5);
  padding: 1rem;
  min-height: 50vh;
}
.toolbar-class {
  border: 1px solid var(--primary-color-5);
}

.preview p {
  color: var(--text-color);
  margin-bottom: 1rem;
}

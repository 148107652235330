.category-tabs {
  background-color: var(--primary-color-5);
}

.category-tabs .landing-section-content {
  min-height: 0px !important;
}

.category-tabs .tabs-container {
  min-height: 50px;
  align-items: center;
  display: flex;
  overflow-x: auto;
}

.category-tabs .tabs-container .category-tab {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.category-tabs .tabs-container .category-tab span {
  font-weight: var(--font-weight-md);
  color: var(--text-color-60);
  white-space: nowrap;
}

.category-tabs .tabs-container .category-tab.active {
  border-bottom: 2px solid var(--primary-color);
}

.category-tabs .tabs-container .category-tab.active span {
  color: var(--primary-color);
}

@media screen and (max-width: 500px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

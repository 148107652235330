.auth-form {
  background-color: var(--light-text);
  min-height: 100px;
  border-radius: var(--border-radius-md);
  box-shadow: var(--form-box-shadow);
  padding: 1.5rem;
}

.auth-form .title {
  min-height: 45px;
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;
}

.auth-form .title p {
  margin-top: 0.5rem;
}

.auth-form .footer {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form .footer span {
  font-weight: var(--font-weight);
}

@media screen and (max-width: 500px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .category-tabs {
    padding-top: 2rem;
  }
}

.card-count-container {
  border-top-width: 3px;
  border-top-style: solid;
}

.card-count-container .card-body {
  display: flex;
  min-height: 112px;
  align-items: center;
  gap: 1.5rem;
}

.card-count-container .card-count-icon-container {
  width: 34px;
  height: 34px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-count-container .card-count-icon-container span {
  color: var(--light-color);
}

.card-count-container .card-count-content {
  flex: 1;
}

.card-count-container .card-count-content .count {
  margin-bottom: 0.5rem;
}

.table thead th {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.text-overflow {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cell-width {
  max-width: 50vh;
}

.table-condensed {
  border: 0;
}

.table tbody > tr {
  border-bottom: 1px solid var(--primary-color-5);
  cursor: pointer;
  /* box-shadow: rgba(181, 181, 181, 0.1) 0px 4px 6px -1px,
    rgba(192, 192, 192, 0.06) 0px 2px 1px -1px; */
  border-radius: var(--border-radius);
}

/* .modal-content .table-responsive {
  overflow-y: hidden;
} */

.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
  position: static;
}

.table-filters {
  margin-bottom: 2rem;
}

.table-card-header {
  background-color: var(--text-color-10);
}

.sort-btn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.3;
}

.sort-btn i {
  font-size: 19px;
  padding: 0;
  margin: 0;
}

.sort-btn:hover,
.sort-btn:active {
  opacity: 1;
  color: var(--text-color-10);
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--text-color-10);
}

td {
  vertical-align: middle;
}

.search-container {
  position: relative;
}

.search-container button {
  margin-left: 15px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.checkbox-container-table {
  display: flex;
  align-items: center;
}

.checkbox-container-table input {
  margin-right: 8px;
}

.table .approver-container {
  display: flex;
  align-items: center;
  position: relative;
}

.table .approver-container .approver-item {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 10px;
  border: 2px solid var(--light-color);
}

.table .approver-container .approver-item span {
  color: var(--light-color);
  font-size: var(--font-size-sm);
}

.table .multi-buttons-actions-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.table .table-raw-multiple-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-multi-answer {
  min-height: 30px;
  display: flex;
  padding: 0.5rem 0;
  flex-direction: column;
  border-bottom: 1px solid var(--text-color-10);
}

.table-multi-answer .table-multi-answer-title {
  font-weight: var(--font-weight-md);
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.table-header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  margin-bottom: 0.2rem;
  gap: 1rem;
  /* overflow-x: hidden; */
}

.table-header-container .table-search-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex: 1;
}

.status-icon-container {
  background-color: var(--primary-color-10);
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-half);
}

.status-icon-container span {
  color: var(--light-color);
}
.status-icon-container.success {
  background-color: var(--success-color);
}

.status-icon-container.failed {
  background-color: var(--error-color);
}

.steps-container {
  margin: 1.5rem 0.5rem;
}

.steps-container .step-item-container {
  display: flex;
  align-items: center;
  min-height: 80px;
  position: relative;
  opacity: 0.3;
}

.steps-container .step-item-container.active {
  opacity: 1;
}

.steps-container .step-item-container .icon-container {
  background-color: var(--primary-color-10);
  margin-right: 1rem;
}

.steps-container .step-item-container.active .icon-container {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.steps-container .step-item-container.active .icon-container span {
  color: var(--light-color);
}

.notification-counter {
  top: 0;
  right: 5px;
  background-color: var(--error-color);
  height: 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--light-color);
  padding-left: 5px;
  padding-right: 5px;
}

.notification-counter.primary-border {
  border: 2px solid var(--primary-color);
}

.notification-counter span {
  color: var(--light-color);
  font-size: 11px;
  font-weight: var(--font-weight-md);
}

.notification-counter:hover span {
  color: var(--light-color) !important;
}

.notification-counter.small span {
  font-size: 9px;
  height: 12px;
}

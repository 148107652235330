.sidebar-container {
  background-color: var(--light-color);
  height: 100vh;
  overflow-y: auto;
  width: 240px;
  z-index: 1;
  padding-bottom: 60px;
  box-shadow: var(--sidebar-box-shadow);
  padding-top: 0.8rem;
}

.sidebar-container.show {
  display: block;
  margin-left: 0;
  transition: margin-left 0.4s;
}

.sidebar-container.hide {
  margin-left: -240px;
  transition: margin-left 0.4s;
}

.sidebar-container li a {
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 0 1rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.sidebar-container li a:hover,
.sidebar-container li a.active,
.sidebar-container li ul li a.active {
  color: var(--primary-color);
  font-weight: var(--font-weight-md);
}

.sidebar-container li ul.nested {
  display: flex;
  flex-direction: column;
  margin: 0 40px;
}

.sidebar-container li ul.nested li a {
  min-height: 35px;
  cursor: pointer;
}

.sidebar-container li a .menu-icon-container {
  margin-right: 0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-container li a .name-container {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.sidebar-container li a .name-container span {
  font-weight: var(--font-weight-md);
}

.sidebar-container .mobile-sidebar-header-container {
  display: none;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .sidebar-container {
    border-right: 0;
    box-shadow: var(--sidebar-box-shadow);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .sidebar-container .mobile-sidebar-header-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 1rem;
    border-bottom: 1px solid var(--text-color-10);
  }
}

@media screen and (max-width: 768px) {
  .sidebar-container {
    border-right: 0;
    box-shadow: var(--sidebar-box-shadow);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  .sidebar-container .mobile-sidebar-header-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 1rem;
    border-bottom: 1px solid var(--text-color-10);
  }
}

@media screen and (max-width: 980px) {
  .sidebar-container {
    border-right: 0;
    box-shadow: var(--sidebar-box-shadow);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .sidebar-container .mobile-sidebar-header-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 1rem;
    border-bottom: 1px solid var(--text-color-10);
  }
}

@media screen and (max-width: 1200px) {
  .sidebar-container {
    border-right: 0;
    box-shadow: var(--sidebar-box-shadow);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .sidebar-container .mobile-sidebar-header-container {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 1rem;
    border-bottom: 1px solid var(--text-color-10);
  }
}

.sigCanvas {
  flex: 1;
  width: 100%;
  overflow-x: auto;
}

.sigButtons {
  min-width: 80px;
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
}

.sig-img-container img {
  aspect-ratio: 16/4;
  width: 100%;
  object-fit: contain;
}

.modal-header {
  border-bottom: 1px solid var(--text-color-10);
}

.modal-footer {
  padding-right: 1rem !important;
}

.modal_title {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-md);
}

/* confirmation modal styles */
.confirmation-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
}
.confirmation-modal-header .confirmation-modal-icon-container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--text-color-10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation-modal-header .confirmation-modal-icon-container span {
  font-size: 35px;
  color: var(--light-color);
}

.confirmation-modal-header .confirmation-modal-icon-container.success {
  background-color: var(--primary-color);
}

.confirmation-modal-header .confirmation-modal-icon-container.failed {
  background-color: var(--error-color);
}

.confirmation-modal-content {
  padding-bottom: 1rem;
}

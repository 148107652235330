.dashboard-header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
}

.dashboard-header-container .left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-header-container .left-container .dashboard-header-info-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow-x: auto;
  flex-wrap: wrap;
}

.dashboard-header-container
  .left-container
  .dashboard-header-info-container
  .dashboard-header-info-item {
  display: flex;
  align-items: center;
  /* margin-top: 0.6rem; */
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.dashboard-header-container
  .left-container
  .dashboard-header-info-container
  .dashboard-header-info-item
  span {
  flex: 1;
}

.dashboard-header-container
  .left-container
  .dashboard-header-info-container
  .dashboard-header-info-item.bg {
  border-right: 1px solid var(--text-color-10);
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--text-color-10);
  border-radius: 50px;
}

.header-filter-container {
  background-color: var(--light-color);
  padding: 0 1rem 1rem 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  box-shadow: var(--card-shadow);
  margin-bottom: 1rem;
}

.card-header-container {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.card-header-container h3 {
  font-size: var(--font-size);
}

.card-header-container .card-header-action-btn {
  display: flex;
  align-items: center;
}

.card-header-container.border-bottom {
  border-bottom: 1px solid var(--text-color-10);
}

.chart-container {
  min-height: 250px;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.chart-container.border {
  border: 1px solid var(--text-color-10);
  border-radius: 8px;
}

.chart-container .header-container {
  min-height: 50px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  border-bottom: 0;
}

.chart-container .doughnut-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
}

.chart-container .empty-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
}

.chart-container .empty-chart span {
  font-weight: var(--font-weight-md);
  opacity: 0.5;
}

.splash-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: var(--bg-color);
}

.splash-container .fetch-service-error-container {
  max-width: 300px;
  margin-top: 2rem;
}

.splash-container .fetch-service-error-container p {
  margin-bottom: 1rem;
}

.splash-logo-container {
}

.splash-logo-container img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.splash-logo-container span {
  color: var(--text-color);
  font-weight: 300;
}

/* MEDIA QUERIES - RESPONSIVE CONTROL */
@media screen and (max-width: 500px) {
  .splash-container {
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 980px) {
}

.service-list-container .service-item-content {
  max-height: 60vh;
  overflow-y: auto;
  background-color: var(--light-color);
}

.service-list-container .loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
}

.service-list-container .service-list-item {
  display: flex;
  min-height: 55px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--text-color-5);
  transition: background-color 0.4s;
}

.service-list-container .service-list-item .name {
  flex: 1;
}

.service-list-container .service-list-item .name .service-name {
  text-transform: capitalize;
}

.service-list-container .service-list-item span {
  color: var(--text-color);
  font-weight: var(--font-weight);
}

.service-list-container .service-list-item:hover {
  background-color: var(--text-color-5);
  cursor: pointer;
}

.service-item-content-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}

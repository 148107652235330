.activities-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.activities-container .card-body {
  height: 295px;
  overflow-y: auto;
}

.home-analytics-count-container .card {
  margin-bottom: 1rem;
}

.home-analytics-count-container .card .count-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s;
}

.home-analytics-count-container .card .count-item:hover {
  opacity: 0.5;
}

.home-analytics-count-container .card .count-item .count {
  min-width: 50px;
}

.home-analytics-count-container .card .count-item .count-title-container {
  flex: 1;
  /* margin: 0 1rem; */
}

.activity-item-container {
  min-height: 45px;
  padding: 0.5rem;
}

.activities-container .activity-definition {
  margin: 0 0.3rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-half);
  background-color: transparent;
  transition: background-color 0.4s;
  cursor: pointer;
}

.icon-container.active {
  background-color: var(--primary-color);
}

.icon-container span {
  font-size: var(--font-size-md-xlg);
  color: var(--text-color);
}

.icon-container.sm span {
  font-size: var(--font-size-md);
}

.icon-container:hover {
  background-color: var(--text-color-10);
}

.icon-container.no-hover-bg:hover {
  background-color: transparent;
}

.material-symbols-outlined {
  color: var(--text-color-60);
  font-size: 20px;
}

.icon-primary-color {
  color: var(--primary-color);
}

.icon-container.active span {
  color: var(--light-color);
  font-size: 16px;
}

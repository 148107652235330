.user-container {
  display: flex;
  min-height: 60px;
  gap: 1.5rem;
}

.user-left-container {
  display: flex;
  gap: 1.5rem;
  flex: 1;
}

.user-container .user-img-container {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-img-container img {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-half);
}

.user-container .user-name-container {
  flex: 1;
}

.questionnaire-container .questionnaire-item-container {
  padding: 1rem 0;
  display: flex;
}

.questionnaire-container
  .questionnaire-item-container
  .questionnaire-answer-count {
  margin-right: 1rem;
  background-color: var(--primary-color);
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-color);
}

.questionnaire-container .questionnaire-item-container .question-text {
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.steps-container {
  margin: 1.5rem 0.5rem;
}

.steps-container .step-item-container {
  display: flex;
  align-items: center;
  min-height: 80px;
  position: relative;
  opacity: 0.3;
}

.steps-container .step-item-container.active {
  opacity: 1;
}

.steps-container .step-item-container .icon-container {
  background-color: var(--primary-color-10);
  margin-right: 1rem;
}

.steps-container .step-item-container.active .icon-container {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.steps-container .step-item-container.active .icon-container span {
  color: var(--light-color);
}

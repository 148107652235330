.card-shadow {
  border: 0px solid var(--text-color-10);
  box-shadow: var(--card-shadow);
}

.card {
  border: 0;
  box-shadow: var(--card-shadow);
}

.card .card-header {
  background-color: var(--light-color);
  border-bottom: 0;
}

.card-footer {
  background-color: var(--primary-color-5);
  border-top: 0;
}

.card-shadow .card-header {
  padding: 1rem;
  /* background-color: var(--primary-color-5); */
  border-bottom: 0;
  border-bottom: 1px solid var(--text-color-10);
}

.panel-default {
  background-color: var(--primary-color-5);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.card-action-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-action-btn .icon-container {
  opacity: 0.5;
}

.card-action-btn .icon-container:hover {
  opacity: 1;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--text-color-5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--text-color-10);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--text-color-60);
}

::-webkit-scrollbar:hover {
  height: 10px;
}

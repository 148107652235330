.intro-container .search-container {
  margin-bottom: -20px;
}

.search-container {
  position: relative;
}

.search-container .search-results-container {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--light-color);
  min-height: 0px;
  max-height: 40vh;
  overflow-y: auto;
  z-index: 10000;
  margin: 0.5rem 0.8rem;
  border-radius: var(--border-radius);
  box-shadow: var(--form-box-shadow);
  border-bottom: 3px solid var(--primary-color);
}

.search-container .search-results-container .search-results-item {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0 0.6rem;
}

.search-container .search-results-container .search-results-item:hover {
  background-color: var(--primary-color-5);
  cursor: pointer;
}

.search-container .search-results-container .search-results-item .name {
  flex: 1;
}

.search-container .search-results-container .search-results-item .right-icon {
  display: none;
}

.search-container
  .search-results-container
  .search-results-item:hover
  .right-icon {
  display: flex;
}

@media screen and (max-width: 500px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 980px) {
  .intro-container .search-container {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

:root {
  /* body color  */
  --bg-color: #fbfbfb;

  /* light colors */
  --light-color: #ffffff;

  /* primary colors */
  --primary-color: #1565c0;
  --primary-color-60: #1462bb;
  --primary-color-10: #cbe0f2;
  --primary-color-5: #f1f6fb;

  /* dark primary */
  --dark-primary-color: #0d47a1;
  --dark-primary-color-150: #092d66;
  --dark-primary-color-200: #020a16;

  /* secondary color */
  --secondary-color: #ff6d00;
  --secondary-color-60: #f48e41;
  --secondary-color-10: #f3e0d1;

  /* dark secondary */
  --dark-secondary-color: #ab4b01;
  --dark-secondary-color-60: #d15c02;
  --dark-secondary-color-10: #e66604;

  /* success color */
  --success-color: #008000;
  --success-color-60: #7eb97e;
  --success-color-10: #c5dcc5;

  /* warnings colors */
  --warning-color: #ff6d00;
  --warning-color-60: #f48e41;
  --warning-color-10: #f3e0d1;

  /* errors colors */
  --error-color: #d10000;
  --error-color-60: #d04242;
  --error-color-10: #fac3c3;

  /* text colors */
  --text-color: #020a16;
  --text-color-60: #637186;
  --text-color-10: #dce1e8;
  --text-color-5: #f3f3f3;

  /* border colors */
  --border-color: #ddd;

  /* radius */
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-md: 16px;
  --border-radius-lg: 32px;
  --border-radius-half: 50%;

  /* margins */
  --margin: 16px;
  --margin-sm: 8px;
  --margin-lg: 32px;

  /* paddings */
  --padding: 16px;
  --padding-sm: 8px;
  --padding-lg: 32px;

  /* fonts size */
  --font-size: 14px;
  --font-size-xsm: 12px;
  --font-size-sm: 13px;
  --font-size-md: 16px;
  --font-size-md-lg: 18px;
  --font-size-md-xlg: 20px;
  --font-size-lg: 30px;
  --font-size-xlg: 60px;

  /* fonts weight */
  --font-weight-sm: 300;
  --font-weight: 600;
  --font-weight-md: 700;
  --font-weight-lg: 900;

  /* heights */
  --navbar-height: 55px;

  /* shadows */
  --navbar-box-shadow: 0 3px 6px rgba(7, 141, 203, 0.058),
    0 3px 6px rgba(1, 64, 113, 0.071);

  --form-box-shadow: 0 19px 38px rgba(232, 235, 239, 0.5),
    0 15px 12px rgba(232, 235, 239, 0.5);

  --sidebar-box-shadow: 0 19px 38px rgba(214, 214, 214, 0.3),
    0 15px 12px rgba(199, 199, 199, 0.22);

  --dropdown-shadow: 0 19px 38px rgba(214, 214, 214, 0.3),
    0 15px 12px rgba(199, 199, 199, 0.22);

  --card-shadow: rgba(159, 159, 159, 0.1) 1px 4px 6px -1px,
    rgba(172, 172, 172, 0.06) 1px 2px 2px -1px;
}

.questionnaire-item-container {
  /* padding: 1rem 0; */
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
  min-height: 60px;
  margin-bottom: 1rem;
}

.questionnaire-item-container .questionnaire-answer-count {
  margin-right: 1rem;
  background-color: var(--primary-color);
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-half);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-color);
}

.questionnaire-item-container .question-text {
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.questionnaire-card-view-actions-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.questionnaire-card-view-actions-buttons .item {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.questionnaire-card-view-actions-buttons .item.active {
  opacity: 1;
}

.questionnaire-card-view-actions-buttons .item.active span {
  color: var(--primary-color);
}

.badge {
  background-color: var(--text-color-10);
  color: var(--text-color);
  text-transform: capitalize;
}

.badge.badge-success,
.badge.badge-approved,
.badge.badge-Approved,
.badge.badge-Approval {
  background-color: var(--success-color-10);
  color: var(--success-color);
}

.badge.badge-rejected,
.badge.badge-Rejected,
.badge.badge-Rejection {
  background-color: var(--error-color-10);
  color: var(--error-color);
}

.badge-lg {
  font-size: var(--font-size);
}

.landing-navbar-container {
  background-color: var(--primary-color);
  box-shadow: var(--navbar-box-shadow);
  position: relative;
  transition: position 0.4s;
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.landing-navbar-container .menu-landing-container {
  min-height: var(--navbar-height);
  display: flex;
  align-items: center;
  height: 100%;
}

.landing-navbar-container .left-container {
  flex: 1;
}

.landing-navbar-container .left-container,
.landing-navbar-container .right-container {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  /* gap: 1rem; */
}

.landing-navbar-container .mobile-navbar {
  display: none;
  transition: 0.4s;
}

.navbar-notification-container {
  position: relative;
}

@media (max-width: 768px) {
  .landing-navbar-container .mobile-navbar.show {
    min-height: var(--navbar-height);
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    /* padding: 0 1rem; */
    background-color: var(--primary-color);
    padding-bottom: 1rem;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 999;
  }

  .landing-navbar-container .mobile-navbar.hide {
    display: none;
  }

  .landing-navbar-container .mobile-navbar .nav-item {
    font-weight: var(--font-weight);
    min-height: 45px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    cursor: pointer;
    padding: 0 1rem;
    opacity: 1;
    transition: opacity 0.4s;
  }

  .landing-navbar-container .mobile-navbar .nav-item:hover {
    opacity: 0.5;
  }

  .landing-navbar-container .mobile-navbar .nav-item a {
    color: var(--light-color);
  }
}

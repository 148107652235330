.features-container {
  display: flex;
  padding-top: 5rem;
  background-color: var(--primary-color-5);
  min-height: 200px;
}

.features-vertical-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.features-vertical-container .item-horizontal {
  display: flex;
  min-height: 130px;
  position: relative;
}

.features-vertical-container .item-horizontal .border-connector {
  position: absolute;
  top: 20px;
  left: 31px;
  border: 1px solid var(--primary-color);
  bottom: 0;
}

.features-container .feature-item {
  display: flex;
  margin-bottom: 2rem;
}

.features .icon-container {
  width: 45px;
  height: 45px;
  z-index: 999;
}

.features .icon-container span {
  color: var(--light-color);
}

.features .feature-description {
  margin-left: 1rem;
  flex: 1;
}

.features .feature-description p {
  margin-top: 0.5rem;
}

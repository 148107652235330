/* file item */
.attachment-item-container {
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: var(--text-color-5);
  padding: 0.5rem 1rem;
  border-radius: 16px;
  cursor: pointer;
}

.attachment-item-container .attachment-item-extension-container {
  height: 30px;
  width: 30px;
  background-color: var(--text-color-5);
  border-radius: 8px;
  margin-right: 1rem;
}

.attachment-item-container .attachment-item-extension-container img {
  height: 30px;
  width: 30px;
}

.attachment-item-container .attachment-item-file-info {
  flex: 1;
}

.attachment-item-container .attachment-item-file-info h3 {
  cursor: pointer;
}

.attachment-item-container .attachment-item-file-info h3:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.attachment-item-action-button-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* file preview styles */

.attachment-preview-container {
  max-height: 80vh;
}

.attachment-preview-container object {
  max-width: 100%;
  height: 80vh;
  display: inline-block;
}

.attachment-preview-container img {
  background-color: #151515;
  /* max-width: 100%;
  height: 80vh;
  display: inline-block; */
  aspect-ratio: 2/2;
  object-fit: contain;
  width: 100%;
  padding: 1rem;
}

.attachment-preview-container .buttons-container {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

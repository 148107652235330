.services-container {
  padding-bottom: 3rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.services-container .service-item-title {
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.services-container .service-item {
  display: flex;
  margin-bottom: 1.5rem;
  opacity: 1;
  transition: opacity 0.4s;
}

.services-container .service-item a {
  color: var(--text-color-60);
  flex: 1;
  text-decoration: none;
  transition: text-decoration 0.4s;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.services-container .service-item a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.services-container .more-results {
  min-height: 45px;
  display: flex;
  align-items: center;
}

.services-container .more-results a {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.4s;
}

.services-container .more-results a:hover {
  opacity: 0.5;
}

.services-container .more-results a span {
  font-size: var(--font-size-);
}

.chart-container .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container .card-header {
  background-color: var(--light-color);
  border-bottom: 0;
  display: flex;
  align-items: center;
}

.chart-container .card-header .title {
  flex: 1;
}
